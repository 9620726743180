<template>
  <div class="tab-box">
    <ul class="tab-list">
      <li v-for="(item,index) in tabList" :key="index" class="tab-item" :class="{'tab-check':item.check}" @click="selectTab(item);">{{item.text}}</li>
    </ul>
    <div class="slot-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "x-tab",
  data () {
    return {
    };
  },
  props: {
    tabList: {
      type: Array,
      default: () => []
    }
  },
  created () { },
  methods: {
    selectTab (item) {
      if (!item.check) {
        this.tabList.map(item => {
          this.$set(item, "check", false);
        });
        this.$set(item, "check", true);
        this.$emit("change", item);
      }
    }
  }
};
</script>

<style scoped>
.tab-box {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  height: 0.52rem;
  box-shadow: 0.02rem 0.02rem 0.04rem 0 rgba(8, 62, 134, 0.07);
  background-color: #ffffff;
}
.tab-list {
  height: 100%;
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
}
.tab-item {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0.13rem;
  font-size: 0.15rem;
  color: #93969d;
}
.tab-check {
  position: relative;
  font-size: 0.16rem;
  color: #2b82f7;
}
.tab-check::before {
  position: absolute;
  content: '';
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 0.2rem;
  height: 0.03rem;
  background-color: #2b82f7;
  border-radius: 0.015rem;
}
.slot-container {
  display: flex;
}
</style>
