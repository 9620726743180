<template>
  <div class="app-box">
    <div class="search-box">
      <input type="text" :placeholder="placeholder" class="search-txt" v-model="keyWord" @input="inputEvent">
      <button class="cancel-btn" @click="$router.back();">取消</button>
    </div>
    <x-tab :tabList="tabList" @change="tabChange"></x-tab>
    <x-scroll-view :top="1.04" bg="#F6F8FB" @scrollLoadData="scrollLoadData">
      <x-list-item v-if="xhrData.length>0" :xhrData="xhrData" @itemClick="itemClick"></x-list-item>
      <x-no-data v-if="ajaxEnd&&!isNextPage&&xhrData.length>0"></x-no-data>
      <x-no-data-bg v-if="ajaxEnd&&xhrData.length<=0&&pageNum<=1"></x-no-data-bg>
    </x-scroll-view>
  </div>
</template>

<script>
import tab from "@/components/tab";
import scrollView from "@/components/scrollView";
import noData from "@/components/noData";
import noDataBg from "@/components/noDataBg";
import listItem from "@/components/visitRecord/listItem";
import { queryList } from "@/api/visitRecord";
import { page } from "@/script/mixins/page";

export default {
  data () {
    return {
      placeholder: "请输入机构或经销商名称",
      type: 1,
      keyWord: null,
      timer: null,
      tabList: [{
        id: 1,
        text: "客户名称",
        placeholder: "请输入机构或经销商名称",
        check: true
      }, {
        id: 2,
        text: "维护人",
        placeholder: "请输入维护人",
        check: false
      }]
    };
  },
  mixins: [page],
  created () { },
  methods: {
    inputEvent () {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.xhrData = [];
        this.ajaxEnd = false;
        if (this.keyWord) {
          this.getDataList();
        }
      }, 300);
    },
    getDataList (isReset = true) {
      queryList({
        type: this.type,
        keyWord: this.keyWord,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(xhr => {
        const listData = xhr.data.records;
        this.isNextPage = listData.length >= this.pageSize;
        isReset && (this.xhrData = []);
        listData.map(item => {
          this.xhrData.push(item);
        });
        this.ajaxEnd = true;
      });
    },
    itemClick (item) {
      this.$router.push({
        path: `/visitRecord/detail/${item.id}`
      });
    },
    tabChange (item) {
      this.placeholder = item.placeholder;
      this.xhrData = [];
      this.keyWord = null;
      this.ajaxEnd = false;
      this.type = item.id;
    }
  },
  components: {
    [tab.name]: tab,
    [scrollView.name]: scrollView,
    [noData.name]: noData,
    [noDataBg.name]: noDataBg,
    [listItem.name]: listItem
  }
};
</script>

<style scoped>
</style>
